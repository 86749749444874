body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#player-el{
  color: white;
}
h1 {
    color: goldenrod;
    margin-right: auto;
    margin-left:auto;
    display: block;
    width: 22%;
    font-size: 24px;
    text-align: center;
}

#message-el {
    margin-left: auto;
    margin-right:auto;
    display: block;
    width: 80%;
    color: white;
    font-size: 18px;
    text-align: center;
}
.card{
    color:white;
    margin-left:auto;
    margin-right:auto;
    display: block;
    width:30%;
    font-size:18px;
    text-align: center;
}
.sum{
  color:white;
    margin-left:auto;
    margin-right:auto;
    display: block;
    width:25%;
    font-size: 18px;
    text-align: center;

}
@media only screen and (min-width: 1200px){
  /*Tablets [601px -> 1200px]*/

  h1 {
    color: goldenrod;
    margin-right: auto;
    margin-left:auto;
    display: block;
    width: 10%;
    font-size: 50px;
}

#message-el {
    margin-left: auto;
    margin-right:auto;
    display: block;
    width: 50%;
    color: white;
    font-size: 35px;
    text-align: center;
}
.card{
    color:white;
    margin-left:auto;
    margin-right:auto;
    display: block;
    width:80%;
    font-size:30px;
    text-align: center;
    margin-left: 155px;
}
.sum{
  color:white;
    margin-left:auto;
    margin-right:auto;
    display: block;
    width:20%;
    font-size: 30px;
    text-align: center;

}
  
}
.btn{
  display: block;
  width: 200px;
  padding:10px 50px 10px 50px;
 font-size: 16px;
 font-weight: bold;
 background-color: goldenrod;
 border: none;
 border-radius: 3px;
 cursor: pointer;
 color:#013f32;
 margin:8px 0 8px 0;
 text-align: center;

}
.btn-div{
  margin-left:auto ;
  margin-right: auto;
  text-align: center;
  display: block;
  width: 200px;
}
.btn:hover{
  background-color: bisque;
}
@media only screen and (max-width:280px){
  #message-el {
    margin-left: auto;
    margin-right:auto;
    display: block;
    width: 70%;
    color: white;
    font-size: 20px;
}
.sum{
  color:white;
    margin-left:auto;
    margin-right:auto;
    display: block;
    width:50%;
    font-size: 16px;
    text-align: center;

}
h1 {
  color: goldenrod;
  margin-right: auto;
  margin-left:auto;
  display: block;
  width: 30%;
  font-size: 30px;
  text-align: center;
}
#message-el {
  margin-left: auto;
  margin-right:auto;
  display: block;
  width: 80%;
  color: white;
  font-size: 14px;
  text-align: center;
}
.card{
  color:white;
  margin-left:auto;
  margin-right:auto;
  display: block;
  width:50%;
  font-size:16px;
  text-align: center;
  
}
}